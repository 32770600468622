.adhesion {
    padding: 60px 0;
    background-color: #f7f7f7;
  }
  
  .adhesion h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-family: 'Lobster', cursive;
    color: #004080;
  }
  
  .adhesion .intro {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #333;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .adherent-icon, .secret-icon {
    font-size: 2.5rem;
    margin-bottom: 15px;
    color: #004080;
    transition: transform 0.3s, color 0.3s;
  }
  
  .adherent-icon:hover, .secret-icon:hover {
    transform: scale(1.2);
    color: #ff6600;
  }
  
  .form-control {
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 10px 15px;
    font-size: 1rem;
    color: #555;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .form-control:focus {
    border-color: #004080;
    box-shadow: 0 0 8px rgba(0, 64, 128, 0.5);
  }
  
  .btn {
    padding: 10px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 30px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .btn:hover {
    background-color: #ff6600;
    transform: scale(1.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  