/* Section Contact */
.contact {
    padding: 60px 0;
    background-color: #f9f9f9;
  }
  
  .contact h1 {
    font-size: 2.8rem;
    margin-bottom: 20px;
    font-family: 'Lobster', cursive;
    color: #004080;
    text-transform: uppercase;
    animation: fadeInDown 1s forwards;
  }
  
  .contact .intro {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #333;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    animation: fadeInDown 1s forwards 0.3s;
  }
  
  .contact-details {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px;
    margin-top: 50px;
    flex-wrap: wrap;
  }
  
  .contact-item {
    flex: 1;
    min-width: 280px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .contact-info {
    background-color: #ffffff;
    border: none;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
  
  .contact-info:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  }
  
  .card-title {
    font-size: 1.6rem;
    color: #004080;
    margin-bottom: 15px;
  }
  
  .card-text {
    font-size: 1rem;
    color: #666;
  }
  
  .animated-icon {
    transition: transform 0.4s ease, color 0.3s ease;
    color: #004080;
    margin-bottom: 20px;
  }
  
  .contact-info:hover .animated-icon {
    transform: rotate(360deg) scale(1.2);
    color: #ff8000;
  }
  
  /* Keyframes for animations */
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px) scale(0.95);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
  
  /* Responsiveness */
  @media (max-width: 991.98px) {
    .contact-details {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-item {
      width: 100%;
      max-width: 400px;
    }
  
    .contact h1 {
      font-size: 2.2rem;
    }
  
    .contact .intro {
      font-size: 1.1rem;
    }
  
    .card-title {
      font-size: 1.4rem;
    }
  
    .card-text {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 767.98px) {
    .contact {
      padding: 30px 0;
    }
  
    .contact h1 {
      font-size: 1.8rem;
    }
  
    .contact .intro {
      font-size: 1rem;
    }
  
    .card-title {
      font-size: 1.3rem;
    }
  
    .card-text {
      font-size: 0.9rem;
    }
  }
  