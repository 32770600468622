.navbar {
    background-color: #0c3015;
    padding: 20px 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.navbar-toggler {
    border-color: #e6b5b5;
    color: #ffffff;
    border-radius: 5px;
    background-color: #045c1e;
}

/* Logo dans la navbar */
.navbar-brand img {
    border: none; /* Supprime la bordure de couleur */
    margin-right: 10px; /* Ajoute un espace entre le logo et le texte PNGP */
    width: 70px;
    height: auto;
    border-radius: 50%;
}


.navbar-brand {
    color: #ffffff;
    font-weight: 700;
    font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 15px;
}

.nav-item {
    margin-left: 40px;
}

.nav-link {
    color: #ffffff; /* Blanc par défaut */
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    transition: color 0.3s ease-in-out;
}

/* Couleur orange pour le lien actif et lors du survol */
.nav-link.active, .nav-link:hover {
    color: #1a4df7 !important; /* Important pour s'assurer que la couleur reste orange */
}

/* Style spécifique pour le bouton Adhésion */
.nav-link.btn {
    padding: 8px 20px;
    background-color: #ff8000;
    color: #ffffff;
    border-radius: 5px;
    font-weight: bold;
}

.nav-link.btn:hover {
    color: #ffffff;
    background-color: #cc6600;
}

/* Responsive */
@media (max-width: 991.98px) {
    .navbar {
        padding: 10px;
    }

    .navbar-brand {
        font-size: 1.5rem;
    }

    .nav-item {
        margin-left: 20px;
    }
}

.nav-link i {
    margin-right: 0px;
}

@media (max-width: 767.98px) {
    .navbar-nav {
        text-align: center;
    }

    .nav-item {
        margin-left: 0;
        margin-top: 10px;
    }

    .nav-link {
        font-size: 0.95rem;
    }

    .navbar-brand {
        font-size: 1.3rem;
    }
}
