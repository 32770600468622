.footer {
    background-color: #222;
    color: #ccc;
    padding: 30px 0;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
    /* height: 90vh; */

  }
  
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer p {
    margin: 0;
    font-size: 15px;
    font-weight: 300;
    color: #bbb;
    animation: fadeInUp 1s ease-in-out;
  }
  
  .footer .social-icons {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .footer .social-icons li {
    display: inline-block;
  }
  
  .footer .social-icons a {
    color: #fff;
    font-size: 22px;
    transition: color 0.4s, transform 0.4s;
  }
  
  .footer .social-icons a:hover {
    color: #1da1f2;
    transform: scale(1.3) rotate(360deg);
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .footer .container {
      flex-direction: column;
    }
  
    .footer .row {
      flex-direction: column;
      text-align: center;
    }
  
    .footer p {
      margin-bottom: 10px;
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  