/* Section Programme */
.programme {
    padding: 60px 0;
    background-color: #f5f5f5;
  }
  
  .programme h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-family: 'Lobster', cursive;
    color: #004080;
  }
  
  .programme .intro {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #333;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .card {
    background-color: #ffffff;
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    transform-style: preserve-3d;
  }
  
  .card:hover {
    transform: rotateY(10deg) translateY(-10px);
  }
  
  .card-body {
    padding: 20px;
  }
  
  .card-title {
    font-size: 1.5rem;
    color: #004080;
    margin-bottom: 15px;
  }
  
  .card-text {
    font-size: 1rem;
    color: #666;
  }
  
  .btn-primary {
    background-color: #ff8000;
    border-color: white;
    padding: 10px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    margin-bottom: 30px;
  }
  
  .btn-primary:hover {
    background-color: #002d50;
    border-color: #002d50;
    transform: scale(1.1);
  }
  
  /* Animation classes */
  .animate-title {
    animation: slideDown 1.5s ease-out;
  }
  
  .animate-fade {
    animation: fadeIn 2s ease-in-out;
  }
  
  .animate-bounce {
    animation: bounce 2s infinite;
  }
  
  .animate-card {
    opacity: 0;
    animation: fadeInUp 1.5s ease forwards;
  }
  
  .icon-pulse {
    animation: pulse 1.5s infinite;
  }
  
  /* Keyframes for animations */
  @keyframes slideDown {
    from {
      transform: translateY(-100px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Responsiveness */
  @media (max-width: 991.98px) {
    .programme h1 {
      font-size: 2rem;
    }
  
    .programme .intro {
      font-size: 1.1rem;
    }
  
    .card-title {
      font-size: 1.3rem;
    }
  
    .card-text {
      font-size: 0.95rem;
    }
  
    .btn-primary {
      font-size: 1rem;
      padding: 8px 20px;
    }
  }
  
  @media (max-width: 767.98px) {
    .programme {
      padding: 30px 0;
    }
  
    .programme h1 {
      font-size: 1.8rem;
    }
  
    .programme .intro {
      font-size: 1rem;
    }
  
    .card-title {
      font-size: 1.2rem;
    }
  
    .card-text {
      font-size: 0.9rem;
    }
  
    .btn-primary {
      font-size: 0.9rem;
      padding: 8px 15px;
    }
  }
  