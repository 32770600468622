/* Section Équipe */
.team {
    padding: 60px 0;
    background-color: #f0f0f0;
    overflow: hidden;
  }
  
  .team h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-family: 'Lobster', cursive;
    color: #004080;
    opacity: 0;
    transform: translateY(-20px);
    animation: fadeInDown 1s forwards;
  }
  
  .team .intro {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #333;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transform: translateY(-20px);
    animation: fadeInDown 1s forwards 0.3s;
  }
  
  /* Uniform Card Heights */
  .team-member {
    background-color: #ffffff;
    border: none;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transform: scale(0.95);
    animation: fadeInUp 1s forwards 0.6s;
  }
  
  .team-member:hover {
    transform: translateY(-15px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  }
  
  .card-body {
    padding: 30px;
    position: relative;
    z-index: 2;
  }
  
  .card-title {
    font-size: 1.6rem;
    color: #004080;
    margin-bottom: 15px;
  }
  
  .card-text {
    font-size: 1rem;
    color: #666;
  }
  
  .animated-icon {
    transition: transform 0.4s ease, color 0.3s ease;
    display: inline-block;
  }
  
  .team-member:hover .animated-icon {
    transform: rotate(360deg) scale(1.2);
    color: #ff8000;
  }
  
  /* Hover effects: Overlay */
  .team-member::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
  }
  
  .team-member:hover::before {
    opacity: 1;
  }
  
  /* Flexbox for uniform card heights */
  .d-flex {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  
  /* Keyframes for animation */
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px) scale(0.95);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
  
  /* Icons color customization */
  .text-primary {
    color: #004080 !important;
  }
  
  .text-success {
    color: #1f7e35 !important;
  }
  
  .text-warning {
    color: #ff8000 !important;
  }
  
  .text-danger {
    color: #ff0000 !important;
  }
  
  .text-info {
    color: #17a2b8 !important;
  }
  
  .text-secondary {
    color: #6c757d !important;
  }
  
  /* Responsiveness */
  @media (max-width: 991.98px) {
    .team h1 {
      font-size: 2rem;
    }
  
    .team .intro {
      font-size: 1.1rem;
    }
  
    .card-title {
      font-size: 1.4rem;
    }
  
    .card-text {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 767.98px) {
    .team {
      padding: 30px 0;
    }
  
    .team h1 {
      font-size: 1.8rem;
    }
  
    .team .intro {
      font-size: 1rem;
    }
  
    .card-title {
      font-size: 1.3rem;
    }
  
    .card-text {
      font-size: 0.9rem;
    }
  }
  