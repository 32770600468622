/* Section Hero */
.hero {
    background-color: #004080;
    color: #ffffff;
    padding: 50px 0;
    justify-content: space-between;
    animation: fadeIn 2s ease-in-out;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    animation: fadeInLeft 1.5s ease-in-out;
  }
  
  .hero p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    animation: fadeInRight 1.5s ease-in-out;
  }
  
  img {
    max-width: 100%;
    border: 9px solid #ff8000;
    border-radius: 30%;
    transition: transform 0.5s ease;
  }
  
  img:hover {
    transform: scale(1.1);
  }
  
  .hero-buttons {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrer les boutons sur mobile */
    gap: 10px; /* Espace uniforme entre les boutons */
    animation: fadeInUp 2s ease-in-out;
}

@media (min-width: 768px) {
    .hero-buttons {
        flex-direction: row;
        align-items: flex-start; /* Aligner les boutons en ligne sur les grands écrans */
        gap: 20px; /* Espace horizontal entre les boutons sur les écrans larges */
    }
}

.hero-buttons a {
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease;
    display: inline-block;
}


  
  .btn-success, .btn-primary {
    position: relative;
    overflow: hidden;
  }
  
  .btn-success:hover, .btn-primary:hover {
    opacity: 0.9;
    transform: scale(1.05);
  }
  
  /* Effet de survol avec animation */
  .btn-success:hover::before, .btn-primary:hover::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, 0.15);
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.5s ease;
    border-radius: 50%;
  }
  
  .btn-success:hover::before, .btn-primary:hover::before {
    transform: translate(-50%, -50%) scale(1);
  }
  
  /* Section Objectives */
  .objectives {
    background-color: #f8f9fa;
    padding: 50px 0;
    animation: fadeInUp 1.5s ease-in-out;
  }
  
  .objectives .col-md-4 i {
    font-size: 3rem;
    margin-bottom: 20px;
    transition: transform 0.3s, color 0.3s;
    animation: bounceIn 1.5s ease-in-out;
  }
  
  .objectives .col-md-4 i:hover {
    transform: rotate(360deg) scale(1.2);
    color: #ff8000;
  }
  
  /* Section Values */
  .values {
    padding: 50px 0;
    background-color: #ffffff;
    animation: fadeInUp 1.5s ease-in-out;
  }
  
  .values .col-md-4 i {
    font-size: 3rem;
    margin-bottom: 20px;
    transition: transform 0.3s, color 0.3s;
    animation: bounceIn 1.5s ease-in-out;
  }
  
  .values .col-md-4 i:hover {
    transform: scale(1.2);
    color: #1f7e35;
  }
  
  /* Keyframes pour les animations personnalisées */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeInLeft {
    from { opacity: 0; transform: translateX(-50px); }
    to { opacity: 1; transform: translateX(0); }
  }
  
  @keyframes fadeInRight {
    from { opacity: 0; transform: translateX(50px); }
    to { opacity: 1; transform: translateX(0); }
  }
  
  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(50px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes bounceIn {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    from { opacity: 0; transform: translate3d(0, -3000px, 0); }
    60% { opacity: 1; transform: translate3d(0, 25px, 0); }
    75% { transform: translate3d(0, -10px, 0); }
    90% { transform: translate3d(0, 5px, 0); }
    to { transform: translate3d(0, 0, 0); }
  }
